import React from 'react';
import Kontakt_card from '../Components/Kontakt_card';
import Contact_us_text from '../Components/Contact_us_text';
import Std_Master from '../Master/Std_Master';
import logo_new_text from "../Gfx/logo_new_text.png";
import logo_new_text_white from "../Gfx/logo_new_text_white.png";

import Empolye_pic from "../Gfx/Empolye_pic.png";
import CheckOut from './CheckOut';
import NewCheckout from '../Components/NewCheckout';

import jack from "../Gfx/jack.png";
import joni from "../Gfx/joni.png";
import georg from "../Gfx/georg.png";
import jack_m from "../Gfx/jack_m.png";
import afram from "../Gfx/afram.png";
import gabbe from "../Gfx/gabbe.png";
import johnnie from "../Gfx/johnnie.png";
import mattias from "../Gfx/mattias.png";
import david from "../Gfx/david.png";

import mariam from "../Gfx/mariam.png";
import stefan from "../Gfx/Stefan.png";
import ritta from "../Gfx/ritta.png";


import {setCookie ,getCookie, removeCookie, spliceCookie, spliceCookieCustomProduct } from '../Components/CookieHandler'
import { Helmet } from 'react-helmet';

export default function Home() {

  var cookie = JSON.parse(getCookie("products"));
  var paketProducts = JSON.parse(getCookie("paket"));

  return(
    <Std_Master hightOfTheNavBar={"bg-white h-20"} mobileNavBarCssWhenNotScroll={"text-black"} selectedNavItem={4} logo_1={logo_new_text} logo={logo_new_text} mobileLogo={logo_new_text} mobileLogo_1={logo_new_text_white} NavCss={"text-black"} Title="." mobileNavBarCss={"text-black"} inner={
      <div className="">

          <Helmet replace={true}>
            <title>Kontakt</title>
          </Helmet>
        {/* <div className="z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8 py-8">
          <h1 className="text-4xl sm:ml-10 mx-5 font-extrabold text-warm-gray-900 sm:text-4xl lg:text-4xl flex justify-center">Kontakta oss</h1>
          <p className="mt-6 sm:ml-11 ml-6 text-xl text-warm-gray-500  flex justify-center"> Vi ser fram emot att höra från dig </p>
        </div> */}
          {/* <NewCheckout /> */}
          <div className="">
            {/* <Contact_us_text /> */}
          </div>
        <div className="max-w-7xl mx-auto py-16 px-4 sm:py-16 sm:px-6 lg:px-24">
          <h1 className="text-4xl sm:ml-10 mx-5 font-extrabold text-warm-gray-900 sm:text-4xl lg:text-4xl flex justify-start">Kontakta oss</h1>
          <p className="mt-6 sm:ml-11 ml-6 text-xl text-warm-gray-500 flex justify-start"> Vi ser fram emot att höra från dig </p> {/* my-12 */}
          <Kontakt_card />
        </div>
      </div>
      
    } />
  );
}

export function Employees(){
  return(

  <div>
   
   {/* <div className="flex flex-wrap">
                <div className="w-1/3">
                  <div className="rounded-full overflow-hidden">
                    <img src={jack_m} alt="Team Member 1" className="w-full"/>
                  </div>
                  <p className="text-center pt-2">Team Member 1</p>
                </div>
                
              </div> */}
        <div className="w-full flex justify-center relative">
          <div className="w-full md:w-3/5 grid-rows-3">
            <div className="row-span-1 flex justify-center items-center flex-col text-center my-4 mb-12">
              <p className="text-3xl font-extrabold tracking-tight sm:text-4xl text-black">Team</p>
              {/* <p className="text-text-xl text-gray-500">Ornare sagittis, suspendisse in hendrerit quis. Sed dui aliquet lectus sit pretium egestas vel mattis neque.</p> */}
            </div>

            <div className="row-span-1 sm:grid-cols-3 grid-cols-2 flex-wrap my-4 grid md:w-full gap-4 pb-12">

              <div className="col-span-1 flex justify-center items-center flex-col">
                <div style={{backgroundImage: "url(" + jack_m + ")"}} className="w-[200px] h-[200px] rounded-full bg-cover bg-center overflow-hidden" />
                <h3 className="text-lg leading-6 font-medium">Jack M</h3>
                <a className="bg-clip-text text-transparent bg-gradient-to-b from-purple-500 to-indigo-500" href="mailto:jack.m@smartcash.se">jack.m@smartcash.se</a>
                {/* <p className="text-gray-600 text-sm">jack.m@smartcash.se</p> */}
                <p className="text-gray-600 text-sm">Tekniker</p>
              </div>

      
              
              <div className="col-span-1 flex justify-center items-center flex-col">
                <div style={{backgroundImage: "url(" + gabbe + ")"}} className="w-[200px] h-[200px] rounded-full bg-cover bg-center overflow-hidden" />
                <h3 className="text-lg leading-6 font-medium">Gabriel</h3>
                <a className="bg-clip-text text-transparent bg-gradient-to-b from-purple-500 to-indigo-500" href="mailto:gabriel@smartcash.se">gabriel@smartcash.se</a>
                {/* <p className="text-gray-600 text-sm">gabriel@smartcash.se</p> */}
                <p className="text-gray-600 text-sm">Tekniker</p>
              </div>
            

            
              
              
              {/* <div className="col-span-1 flex justify-center items-center flex-col">
              <div style={{backgroundImage: "url(" + mattias + ")"}} className="w-[200px] h-[200px] rounded-full bg-cover bg-center overflow-hidden" />
                <h3 className="text-lg leading-6 font-medium">Mattias</h3>
                <a className="bg-clip-text text-transparent bg-gradient-to-b from-purple-500 to-indigo-500" href="mailto:mattias@smartcash.se">mattias@smartcash.se</a>
                <p className="text-gray-600 text-sm">Tekniker</p>
              </div> */}
              <div className="col-span-1 flex justify-center items-center flex-col">
                <div style={{backgroundImage: "url(" + stefan + ")"}} className="w-[200px] h-[200px] rounded-full bg-cover bg-center overflow-hidden" />
                <h3 className="text-lg leading-6 font-medium">Stefan</h3>
                <a className="bg-clip-text text-transparent bg-gradient-to-b from-purple-500 to-indigo-500" href="mailto:stefan@smartcash.se">stefan@smartcash.se</a>
                {/* <p className="text-gray-600 text-sm">@smartcash.se</p> */}
                <p className="text-gray-600 text-sm">Säljare</p>
              </div>
              <div className="col-span-1 flex justify-center items-center flex-col">
                <div style={{backgroundImage: "url(" + ritta + ")"}} className="w-[200px] h-[200px] rounded-full bg-cover bg-center overflow-hidden" />
                <h3 className="text-lg leading-6 font-medium">Ritta</h3>
                <a className="bg-clip-text text-transparent bg-gradient-to-b from-purple-500 to-indigo-500" href="mailto:ritta@smartcash.se">ritta@smartcash.se</a>
                {/* <p className="text-gray-600 text-sm">ritta@smartcash.se</p> */}
                <p className="text-gray-600 text-sm">Ekonomi</p>
              </div>

              {/* <div className="col-span-1 flex justify-center items-center flex-col">
                <div style={{backgroundImage: "url(" + david + ")"}} className="w-[200px] h-[200px] rounded-full bg-cover bg-center overflow-hidden" />
                <h3 className="text-lg leading-6 font-medium">David</h3>
                <a className="bg-clip-text text-transparent bg-gradient-to-b from-purple-500 to-indigo-500" href="mailto:support@smartcash.se">david@smartcash.se</a>
                <p className="text-gray-600 text-sm">Säljare</p>
              </div> */}

              <div className="col-span-1 flex justify-center items-center flex-col">
                <div style={{backgroundImage: "url(" + johnnie + ")"}} className="w-[200px] h-[200px] rounded-full bg-cover bg-center overflow-hidden" />
                <h3 className="text-lg leading-6 font-medium">Johnnie</h3>
                <a className="bg-clip-text text-transparent bg-gradient-to-b from-purple-500 to-indigo-500" href="mailto:support@smartcash.se">johnnie@smartcash.se</a>
                {/* <p className="text-gray-600 text-sm">@smartcash.se</p> */}
                <p className="text-gray-600 text-sm">Utvecklare</p>
              </div>
              

              {/* <div className="col-span-1 flex justify-center items-center flex-col">
                <div style={{backgroundImage: "url(" + afram + ")"}} className="w-[200px] h-[200px] rounded-full bg-cover bg-center overflow-hidden" />
                <h3 className="text-lg leading-6 font-medium">Affe</h3>
                <a className="bg-clip-text text-transparent bg-gradient-to-b from-purple-500 to-indigo-500" href="mailto:support@smartcash.se">affe@smartcash.se</a>
                <p className="text-gray-600 text-sm">Utvecklare</p>
              </div> */}

              <div className="col-span-1 flex justify-center items-center flex-col">
                <div style={{backgroundImage: "url(" + Empolye_pic + ")"}} className="w-[200px] h-[200px] rounded-full bg-cover bg-center overflow-hidden" />
                {/* <div className="w-[200px] h-[200px] sm:w-[120px] sm:h-[120px] rounded-full bg-cover bg-center" style={{backgroundImage: "url(" + logo_new_text + ")"}}></div> */}
                <h3 className="text-lg leading-6 font-medium">Johan</h3>
                <a className="bg-clip-text text-transparent bg-gradient-to-b from-purple-500 to-indigo-500" href="mailto:johan@smartcash.se">johan@smartcash.se</a>
                {/* <p className="text-gray-600 text-sm">affe@smartcash.se</p> */}
                <p className="text-gray-600 text-sm">Utvecklare</p>
              </div>

              

              <div className="col-span-1 flex justify-center items-center flex-col">
                <div style={{backgroundImage: "url(" + jack + ")"}} className="w-[200px] h-[200px] rounded-full bg-cover bg-center overflow-hidden" />
                <h3 className="text-lg leading-6 font-medium">Jack</h3>
                <a className="bg-clip-text text-transparent bg-gradient-to-b from-purple-500 to-indigo-500" href="mailto:info@smartcash.se">info@smartcash.se</a>
                {/* <p className="text-gray-600 text-sm">jackmassi@smartcash.se</p> */}
                <p className="text-gray-600 text-sm">Säljare</p>
              </div>
              <div className="col-span-1 flex justify-center items-center flex-col">
                <div style={{backgroundImage: "url(" + georg + ")"}} className="w-[200px] h-[200px] rounded-full bg-cover bg-center overflow-hidden" />
                <h3 className="text-lg leading-6 font-medium">Georg</h3>
                <a className="bg-clip-text text-transparent bg-gradient-to-b from-purple-500 to-indigo-500" href="mailto:info@smartcash.se">georgmassi@smartcash.se</a>
                {/* <p className="text-gray-600 text-sm">georgmassi@smartcash.se</p> */}
                <p className="text-gray-600 text-sm">Säljare</p>
              </div>
              <div className="col-span-1 flex justify-center items-center flex-col">
                <div style={{backgroundImage: "url(" + joni + ")"}} className="w-[200px] h-[200px] rounded-full bg-cover bg-center overflow-hidden" />
                <h3 className="text-lg leading-6 font-medium">Joni</h3>
                <a className="bg-clip-text text-transparent bg-gradient-to-b from-purple-500 to-indigo-500" href="mailto:info@smartcash.se">info@smartcash.se</a>
                {/* <p className="text-gray-600 text-sm">jonimassi@smartcash.se</p> */}
                <p className="text-gray-600 text-sm">Säljare</p>
              </div>
              
                
              

          </div>
          </div>

        </div>
  </div>
  )
}
